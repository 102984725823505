import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import i18n from '@/i18n';
import store from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        redirect: {
            name: store.getters['authenticationStorage/isAuthenticated']
                ? 'Users'
                : 'Login',
        },
        component: () => import('@/views/Layout.vue'),
        children: [
            {
                path: '/users',
                name: 'Users',
                component: () => import('@/views/Users.vue'),
                meta: {
                    title: 'users',
                },
            },
            {
                path: '/how-to',
                name: 'HowTo',
                component: () => import('@/views/HowTo.vue'),
                meta: {
                    title: 'how-to',
                },
            },
            {
                path: '/how-to-videos',
                name: 'HowToVideos',
                component: () => import('@/views/HowToVideos.vue'),
                meta: {
                    title: 'how-to-videos',
                },
            },
            {
                path: '/terms-and-conditions',
                name: 'TermsAndConditions',
                component: () => import('@/views/TermsAndConditions.vue'),
                meta: {
                    title: 'terms-and-conditions',
                },
            },
            {
                path: '/about-us',
                name: 'AboutUs',
                component: () => import('@/views/AboutUs.vue'),
                meta: {
                    title: 'about-us',
                },
            },
            {
                path: '/support-and-help',
                name: 'SupportAndHelp',
                component: () => import('@/views/SupportAndHelp.vue'),
                meta: {
                    title: 'support-and-help',
                },
            },
            {
                path: '/most-important',
                name: 'MostImportant',
                component: () => import('@/views/MostImportant.vue'),
                meta: {
                    title: 'most-important',
                },
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
        meta: {
            onlyUnauthenticated: true,
            title: 'login',
        },
    },
    {
        path: '*',
        redirect: '/',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to?.matched?.some((record) => record.meta.onlyUnauthenticated)) {
        if (store.getters['authenticationStorage/isAuthenticated']) {
            next({ name: 'Users' });
        } else {
            next();
        }
    } else if (
        to?.matched?.some((record) => record.meta.onlyAdmin) &&
        store.getters['authenticationStorage/isAuthenticated']
    ) {
        if (store.getters['authenticationStorage/getUser']?.role !== 'ADMIN') {
            next({ name: 'Users' });
        } else {
            next();
        }
    } else if (
        store.getters['authenticationStorage/isAuthenticated'] ||
        to?.matched?.some((record) => record.meta.unauthenticated)
    ) {
        next();
    } else {
        next({ name: 'Login' });
    }
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = i18n
            .t('navigation.title', {
                page: i18n.t(
                    to?.meta?.title
                        ? `navigation.pages.${to.meta.title}`
                        : 'kapshoo',
                ),
            })
            .toString();
    });
});

export default router;

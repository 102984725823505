export default function dataURLtoFile(
    dataUrl: string | undefined | null,
    fileName: string,
): File | null {
    if (dataUrl) {
        const arr = dataUrl.split(',');

        if (arr && arr.length > 0) {
            const matches = arr[0].match(/:(.*?);/);
            if (matches && matches.length > 0) {
                const mime = matches[1];
                const bstr = atob(arr[1]);
                let n = bstr.length;
                const u8arr = new Uint8Array(n);
                while (n > 0) {
                    n -= 1;
                    u8arr[n] = bstr.charCodeAt(n);
                }
                return new File([u8arr], fileName, { type: mime });
            }
        }
    }
    return null;
}

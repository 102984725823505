import apiClient from '@/lib/api-client';
import { ApiError } from '@/model/ApiError';
import { MostImportantItem } from '@/model/MostImportantItem';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true, preserveState: false })
export default class MostImportantStorage extends VuexModule {
    error: ApiError | null = null;

    items: MostImportantItem[] | null = null;

    @Mutation
    setError(error: ApiError | null): void {
        this.error = error;
    }

    @Mutation
    setItems(items: MostImportantItem[] | null): void {
        this.items = items?.sort((a, b) => (a?.id ?? 0) - (b?.id ?? 0)) ?? [];
    }

    @Action
    fetch(): Promise<void> {
        return apiClient
            .get<void, MostImportantItem[]>('/most-important')
            .then((data: MostImportantItem[]) => {
                this.context.commit('setItems', data);
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    update(request: MostImportantItem): Promise<void> {
        return apiClient
            .put('/most-important', request)
            .then(() => {
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    delete(request: MostImportantItem | null): Promise<void> {
        if (!request) {
            return Promise.reject();
        }

        return apiClient
            .delete('/most-important', { data: { data: request.id } })
            .then(() => {
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    create(request: MostImportantItem): Promise<void> {
        return apiClient
            .post('/most-important', request)
            .then(() => {
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    get getItems(): MostImportantItem[] | null {
        return this.items;
    }

    get getError(): ApiError | null {
        return this.error;
    }
}

import apiClient from '@/lib/api-client';
import dataURLtoFile from '@/lib/helper/file-helper';
import { ApiError } from '@/model/ApiError';
import { HowToItem } from '@/model/HowTo';
import { MediaRequestResponse } from '@/model/s3';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true, preserveState: false })
export default class HowToStorage extends VuexModule {
    error: ApiError | null = null;

    pages: HowToItem[] | null = null;
    videos: HowToItem[] | null = null;

    @Mutation
    setError(error: ApiError | null): void {
        this.error = error;
    }

    @Mutation
    setPages(items: HowToItem[] | null): void {
        this.pages = items;
    }

    @Mutation
    setVideos(items: HowToItem[] | null): void {
        this.videos = items;
    }

    @Action
    uploadImage(request: HowToItem): Promise<string | null> {
        if (!request.imageFile?.name) {
            return Promise.resolve(null);
        }
        const payload = {
            url: '/how-to/asset',
            fileName: request.imageFile?.name,
            fileType: request.imageFile?.type,
        };

        return this.context
            .dispatch('s3Storage/getPutPresignedUrl', payload, { root: true })
            .then((response: MediaRequestResponse) => {
                return this.context
                    .dispatch(
                        's3Storage/sendFile',
                        { url: response.url, file: request.imageFile },
                        { root: true },
                    )
                    .then(() => {
                        return Promise.resolve(response.key);
                    });
            });
    }

    @Action
    uploadThumbnail(request: HowToItem): Promise<string | null> {
        if (!request.imageFile?.name) {
            return Promise.resolve(null);
        }

        const fileName = `thumbnail-${request.imageFile?.name
            .split('.')
            .slice(0, -1)
            .join()}.jpeg`;

        const payload = {
            url: '/how-to/asset',
            fileName,
            fileType: 'image/jpeg',
        };

        return this.context
            .dispatch('s3Storage/getPutPresignedUrl', payload, { root: true })
            .then((response: MediaRequestResponse) => {
                return this.context
                    .dispatch(
                        's3Storage/sendFile',
                        {
                            url: response.url,
                            file: dataURLtoFile(request.thumbnailKey, fileName),
                        },
                        { root: true },
                    )
                    .then(() => {
                        return Promise.resolve(response.key);
                    });
            });
    }

    @Action
    fetch(): Promise<void> {
        return apiClient
            .get<void, HowToItem[]>('/how-to/pages')
            .then((data: HowToItem[]) => {
                this.context.commit('setPages', data);
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    fetchVideos(): Promise<void> {
        return apiClient
            .get<void, HowToItem[]>('/how-to/videos')
            .then((data: HowToItem[]) => {
                data.splice(0, 0, {} as HowToItem);
                this.context.commit('setVideos', data);
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    update(request: HowToItem): Promise<void> {
        return apiClient
            .put(`/how-to/${request.id}`, request)
            .then(() => {
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    updateOrder(request: HowToItem): Promise<void> {
        return apiClient
            .put(`/how-to/${request.id}/order`, request)
            .then(() => {
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    deleteVideo(request: HowToItem | null): Promise<void> {
        if (!request) {
            return Promise.reject();
        }

        return apiClient
            .delete(`/how-to/videos/${request.id}`)
            .then(() => {
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    createVideo(request: HowToItem): Promise<void> {
        return apiClient
            .post(`/how-to/videos`, request)
            .then(() => {
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    get getPages(): HowToItem[] | null {
        return this.pages;
    }

    get getVideos(): HowToItem[] | null {
        return this.videos;
    }

    get getError(): ApiError | null {
        return this.error;
    }
}

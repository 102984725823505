import apiClient from '@/lib/api-client';
import { ApiError } from '@/model/ApiError';
import { PageContent } from '@/model/PageContent';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true, preserveState: false })
export default class HowToStorage extends VuexModule {
    error: ApiError | null = null;

    aboutUs: PageContent | null = null;
    termsAndConditions: PageContent | null = null;
    support: PageContent[] | null = null;

    @Mutation
    setError(error: ApiError | null): void {
        this.error = error;
    }

    @Mutation
    setAboutUs(item: PageContent | null): void {
        this.aboutUs = item;
    }

    @Mutation
    setTermsAndConditions(item: PageContent | null): void {
        this.termsAndConditions = item;
    }

    @Mutation
    setSupport(items: PageContent[] | null): void {
        this.support = items;
    }

    @Action
    fetchSupports(): Promise<void> {
        return apiClient
            .get<void, PageContent[]>('page-content/support')
            .then((data: PageContent[]) => {
                this.context.commit('setSupport', data);
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    createSupport(item: PageContent): Promise<void> {
        return apiClient
            .post<PageContent, PageContent>('page-content/support', item)
            .then(() => {
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    updateSupport(item: PageContent): Promise<void> {
        return apiClient
            .put<PageContent, PageContent>(
                `page-content/support/${item.id}`,
                item,
            )
            .then(() => {
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    deleteSupport(item: PageContent): Promise<void> {
        return apiClient
            .delete(`page-content/support/${item.id}`)
            .then(() => {
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    fetchTermsAndConditions(): Promise<void> {
        return apiClient
            .get<void, PageContent>('/page-content/terms-and-conditions')
            .then((data: PageContent) => {
                this.context.commit('setTermsAndConditions', data);
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    fetchAboutUs(): Promise<void> {
        return apiClient
            .get<void, PageContent>('/page-content/about-us')
            .then((data: PageContent) => {
                this.context.commit('setAboutUs', data);
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    updateAboutUs(request: PageContent | null): Promise<void> {
        return apiClient
            .put(`/page-content/about-us`, request)
            .then(() => {
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    updateTermsAndConditions(request: PageContent | null): Promise<void> {
        return apiClient
            .put(`/page-content/terms-and-conditions`, request)
            .then(() => {
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    get getAboutUs(): PageContent | null {
        return this.aboutUs;
    }

    get getTermsAndConditions(): PageContent | null {
        return this.termsAndConditions;
    }

    get getSupports(): PageContent[] | null {
        if (this.support) {
            this.support.push({} as PageContent);
        }
        return this.support ?? [{} as PageContent];
    }

    get getError(): ApiError | null {
        return this.error;
    }
}

import { JwtPayload } from '@/model/Authentication';
import jwtDecode from 'jwt-decode';

export function parseToken(token: string): JwtPayload | never {
    let refreshTokenJwtPayload;

    try {
        refreshTokenJwtPayload = jwtDecode<JwtPayload>(token);
    } catch {
        console.error(`Failed to decode jwt token: ${token}`);

        throw new Error('Failed to decode jwt token!');
    }

    return refreshTokenJwtPayload;
}

export function checkTokenExpired(token: string): boolean {
    let isExpired = false;

    const tokenExpiry = parseToken(token)?.exp;

    if (tokenExpiry) {
        isExpired = Date.now() >= tokenExpiry * 1000;
    }

    return isExpired;
}








import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';

@Component({
    methods: {
        ...mapActions({
            fetchUser: 'authenticationStorage/fetchUser',
        }),
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'authenticationStorage/isAuthenticated',
        }),
    },
})
export default class App extends Vue {
    isAuthenticated!: boolean;

    fetchUser!: () => Promise<void>;

    mounted(): void {
        if (this.isAuthenticated) {
            this.fetchUser();
        }
    }
}

import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import authenticationStorage from '@/store/modules/authentication-storage';
import userStorage from '@/store/modules/user-storage';
import howToStorage from '@/store/modules/howto-storage';
import pageContentStorage from '@/store/modules/page-content-storage';
import s3Storage from '@/store/modules/s3-storage';
import mostImportantStorage from '@/store/modules/most-important-storage';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    modules: ['authenticationStorage'],
});

export default new Vuex.Store({
    modules: {
        authenticationStorage,
        userStorage,
        howToStorage,
        s3Storage,
        pageContentStorage,
        mostImportantStorage,
    },
    plugins: [vuexLocal.plugin],
});

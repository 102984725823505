import { ApiError } from '@/model/ApiError';
import { Credential } from '@/model/Authentication';
import HttpEndpoint from '@/model/enums/http/http-endpoint';
import HttpHeader from '@/model/enums/http/http-header';
import HttpStatus from '@/model/enums/http/http-status';
import router from '@/router';
import store from '@/store';
import axios from 'axios';
import { setup } from 'axios-cache-adapter';

const ApiClient = setup({
    cache: {
        maxAge: 10 * 60 * 1000,
        exclude: {
            paths: [new RegExp('^((?!assets/get).)*$')],
            query: false,
        },
    },
});

ApiClient.defaults.baseURL = process.env.VUE_APP_BASE_URL;

ApiClient.interceptors.request.use(async (config) => {
    const credential: Credential =
        store.getters['authenticationStorage/getCredential'];

    if (config?.headers) {
        if (
            credential?.accessToken &&
            Date.now() >= credential.accessTokenExpiration &&
            HttpEndpoint.REFRESH_URL !== config.url
        ) {
            if (Date.now() >= credential.refreshTokenExpiration) {
                store.commit('authenticationStorage/setCredential', null);
                router.push({ name: 'Login' });
                return config;
            }

            // eslint-disable-next-line no-param-reassign
            config.headers[HttpHeader.AUTHORIZATION] = '';

            await store.dispatch('authenticationStorage/refresh');
        }

        const refreshedCredential: Credential =
            store.getters['authenticationStorage/getCredential'];

        if (
            refreshedCredential?.accessToken &&
            HttpEndpoint.REFRESH_URL !== config.url
        ) {
            // eslint-disable-next-line no-param-reassign
            config.headers[
                HttpHeader.AUTHORIZATION
            ] = `Bearer ${refreshedCredential.accessToken}`;
        }
    }

    return config;
});

ApiClient.interceptors.response.use(
    (response) => {
        return Promise.resolve(response?.data);
    },
    (error) => {
        if (error?.response?.status === HttpStatus.TOKEN_EXPIRED) {
            store.commit('authenticationStorage/setCredential', null);
            router.push({ name: 'Login' });
            return Promise.reject(error);
        }

        const apiError: ApiError = { key: 'general.internal.server.error' };

        if (error?.response?.data?.key) {
            apiError.key = error.response.data.key;
        }

        if (error?.response?.data?.validationMessages) {
            apiError.validationMessages =
                error.response.data.validationMessages;
        }

        return Promise.reject(apiError);
    },
);

export function sendFileToS3(url: string, file: File): Promise<void> {
    const options = {
        headers: {
            'Content-Type': file.type,
        },
    };

    return axios
        .put(url, file, options)
        .then(() => {
            return Promise.resolve();
        })
        .catch((err) => {
            console.warn(err);
            return Promise.reject();
        });
}

export default ApiClient;

import apiClient from '@/lib/api-client';
import { ApiError } from '@/model/ApiError';
import Pageable from '@/model/Pageable';
import { User, UserFetchRequest } from '@/model/User';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true, preserveState: false })
export default class UserStorage extends VuexModule {
    error: ApiError | null = null;

    users: Pageable<User> | null = null;

    searchString: string | null = null;

    @Mutation
    setError(error: ApiError | null): void {
        this.error = error;
    }

    @Mutation
    setUsers(newUsers: Pageable<User> | null): void {
        this.users = newUsers;
    }

    @Mutation
    setActiveForUser(request: { userId: number; active: boolean }): void {
        this.users?.items.forEach((user) => {
            if (user.id === request.userId) {
                user.active = request.active;
            }
        });
    }

    @Mutation
    setSearchString(searchString: string | null): void {
        this.searchString = searchString;
    }

    @Action
    async search(searchString: string | null): Promise<void> {
        const value = searchString?.trim();
        this.context.commit('setSearchString', value);
        if (value) {
            this.context.dispatch('fetchUsers', {
                page: 1,
                searchString: value ?? undefined,
            });
        } else {
            this.context.dispatch('fetchUsers', {
                page: 1,
            });
        }
    }

    @Action
    async fetchReferrals(params: UserFetchRequest): Promise<void> {
        this.context.commit('setSearchString', null);
        this.context.dispatch('fetchUsers', params);
    }

    @Action
    fetchUsers(params: UserFetchRequest): Promise<void> {
        return apiClient
            .get<UserFetchRequest, Pageable<User>>('/users', {
                params: {
                    ...params,
                    searchString: this.context.getters.getSearchString,
                },
            })
            .then((data: Pageable<User>) => {
                this.context.commit('setUsers', data);
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    deactivateUserById(id: number): Promise<void> {
        return apiClient
            .delete(`/users/${id}/deactivate`)
            .then(() => {
                this.context.commit('setError', null);
                this.context.commit('setActiveForUser', {
                    userId: id,
                    active: false,
                });
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    reactivateUserById(id: number): Promise<void> {
        return apiClient
            .put(`/users/${id}/reactivate`)
            .then(() => {
                this.context.commit('setError', null);
                this.context.commit('setActiveForUser', {
                    userId: id,
                    active: true,
                });
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    resetPassword(email: string): Promise<void> {
        return apiClient
            .post(`/auth/reset-password-request`, { data: email })
            .then(() => {
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    @Action
    deleteUserById(id: number): Promise<void> {
        return apiClient
            .delete(`/users/${id}`)
            .then(() => {
                this.context.commit('setError', null);
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject();
            });
    }

    get getUsers(): Pageable<User> | null {
        return this.users;
    }

    get getError(): ApiError | null {
        return this.error;
    }

    get getSearchString(): string | null {
        return this.searchString;
    }
}

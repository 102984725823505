import apiClient, { sendFileToS3 } from '@/lib/api-client';
import { ApiError } from '@/model/ApiError';
import { FilePutRequest, FileRequest, MediaRequestResponse } from '@/model/s3';
import { Action, Module, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true, preserveState: false })
export default class S3Storage extends VuexModule {
    @Action
    getPutPresignedUrl(
        request: FilePutRequest,
    ): Promise<MediaRequestResponse | ApiError> {
        const { url, ...params } = request;
        return apiClient
            .get<void, MediaRequestResponse>(url, {
                params,
            })
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error: ApiError) => {
                return Promise.reject(error);
            });
    }

    @Action
    sendFile(request: FileRequest): Promise<string | void> {
        return sendFileToS3(request.url, request.file);
    }

    @Action
    getPresignedUrl(key?: string | null): Promise<string | ApiError | null> {
        if (!key) {
            return Promise.resolve(null);
        }

        return apiClient
            .get<void, string>('/assets/get', {
                params: {
                    key,
                },
            })
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error: ApiError) => {
                return Promise.reject(error);
            });
    }
}

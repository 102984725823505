import apiClient from '@/lib/api-client';
import { ApiError } from '@/model/ApiError';
import {
    Credential,
    TokenRequest,
    LoginFormData,
    LoginRequest,
    LoginResponse,
} from '@/model/Authentication';
import { Module, Mutation, VuexModule, Action } from 'vuex-module-decorators';
import { parseToken } from '@/lib/helper/token-helper';
import { User } from '@/model/User';

@Module({ namespaced: true, preserveState: true })
export default class AuthenticationStorage extends VuexModule {
    credential: Credential | null = null;

    error: ApiError | null = null;

    user: User | null = null;

    @Mutation
    setCredential(loginResponse: LoginResponse | null): void {
        if (loginResponse) {
            const accessTokenJwtPayload = parseToken(
                loginResponse?.accessToken,
            );

            const refreshTokenJwtPayload = parseToken(
                loginResponse?.refreshToken,
            );

            this.credential = {
                ...loginResponse,
                accessTokenExpiration: accessTokenJwtPayload.exp * 1000,
                refreshTokenExpiration: refreshTokenJwtPayload.exp * 1000,
            };
        } else {
            this.credential = null;
        }
    }

    @Mutation
    setError(error: ApiError | null): void {
        this.error = error;
    }

    @Mutation
    setUser(user: User | null): void {
        this.user = user;
    }

    @Action
    login(formData: LoginFormData): Promise<void> {
        return apiClient
            .post<LoginRequest, LoginResponse>('/auth/login', formData)
            .then((data) => {
                this.context.commit('setCredential', data);
                this.context.dispatch('fetchUser');
                return Promise.resolve();
            })
            .catch((error) => {
                this.context.commit('setError', error);
                return Promise.reject(error);
            });
    }

    @Action
    fetchUser(): Promise<void> {
        return apiClient
            .get('/users/me')
            .then((data) => {
                this.context.commit('setUser', data);
                return Promise.resolve();
            })
            .catch((error) => {
                console.warn(error);
                return Promise.reject();
            });
    }

    @Action
    refresh(): Promise<void> {
        return apiClient
            .post<TokenRequest, LoginResponse>('/auth/refresh', {
                token: this.credential?.refreshToken,
            })
            .then((data) => {
                this.context.commit('setCredential', data);
            })
            .catch(() => {
                this.context.commit('setCredential', null);
            });
    }

    @Action
    logout(): Promise<void> {
        return apiClient.post('/auth/logout').then(() => {
            this.context.commit('setCredential', null);
        });
    }

    get getCredential(): Credential | null {
        return this.credential;
    }

    get isAuthenticated(): boolean {
        return !!this.credential?.accessToken;
    }

    get getUser(): User | null {
        return this.user;
    }

    get getError(): ApiError | null {
        return this.error;
    }
}
